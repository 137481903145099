import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js'
//import axios from 'axios'
import global from './components/global.vue'
import myAxios from './assets/myAxios'
Vue.config.productionTip = false

Vue.use(ElementUI)

//axios.defaults.baseURL = "http://48.232.208.85:8080"
// axios.interceptors.response.use(function(response){
//   return response
// },function(error){
//   console.log('error',error.response)
//   if(error.response.status==401){
//     sessionStorage.removeItem('userInfo')
//     setTimeout(()=>{
//       this.$router.push('/login')
//     },2000)
//   }
//     return Promise.reject(new Error(error.response.data.message))
// })
//Vue.prototype.$axios = axios
Vue.prototype.$axios = myAxios
Vue.prototype.global = global

// router.beforeEach((to,from,next)=>{
//   console.log('here')
//   if(to.matched.some(record => record.meta.requireAuth)){
//     if(sessionStorage.getItem('userInfo'))
//       next()
//     else{
//       next({
//         path:'/login',
//         query:{redirect:to.fullPath}
//       })
//     }
//   }else{
//     next()
//   }
// })

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
