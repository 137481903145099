<template>
    <router-view />
</template>

<script>
export default {
  name:'Script',
  created(){
    console.log('debug1',this.props)
  }
}
</script>

<style scoped>

</style>
