<template>
    <router-view/>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  }
};
</script>

<style>
body {
  margin: 0;
}
</style>

