<script>
    const PAGE_SIZE = 10
    const STATES = {
        0:{
            value:0,
            label:"未开始",
            type:"info",      
        },
        1:{
            value:1,
            label:"进行中",
            type:"warning",
        },
        2:{
            value:2,
            label:"已完成",
            type:"success",
        }
    }
    const CHECK_STATES = {
        REJECT:{
            value:0,
            label:"未通过",
            type:"danger"
        },
        ACCEPT:{
            value:1,
            label:"已通过",
            type:"success"
        },
        WAITING_CHECK:{
            value:2,
            label:"待审核",
            type:"warning"
        }
    }
    const USER = [
        // {
        //     value:0,
        //     label:"评论员",
        //     info:"NORMAL_USER",
        // },
        // {
        //     value:1,
        //     label:"部门管理员",
        //     info:"DEPARTMENT_ADMIN",
        // },
        
        // {
        //     value:2,
        //     label:"超级管理员",
        //     info:"SUPER_ADMIN"
        // },
        {
            code: 1,
            name: "成员",
            type: "MEMBER"
        },
        {
            code: 2,
            name: "副大队长",
            type: "FOURTH_TL"
        },
        {
            code: 3,
            name: "大队长",
            type: "THIRD_TL"
        },
        {
            code: 4,
            name: "副支队长",
            type: "SECOND_TL"
        },
        {
            code: 5,
            name: "支队长",
            type: "FIRST_TL"
        },
    ]
    const COMMENTOR_LEVEL = {
        VOLUNTEER:{
            value:0,
            label:"志愿者",
            type:"success",
            info:'VOLUNTEER',
        },
        BASE_MEMBER:{
            value:1,
            label:"基础成员",
            type:"",
            info:"BASE_MEMBER"
        },
        MAINSTAY_MEMBER:{
            value:2,
            label:"骨干成员",
            type:"",
            info:"MAINSTAY_MEMBER"
        },
        CORE_MEMBER:{
            value:3,
            label:"核心成员",
            type:"warning",
            info:"CORE_MEMBER",
        },
        EXPERT_MEMBER:{
            value:4,
            label:"专家成员",
            type:"warning",
            info:"EXPERT_MEMBER"
        }
    }
    const baseUrl = 'https://www.taizhoutaidu.com'
    
    // departmentList:[
    //     {
    //         areaName:,
    //         departmentName:,
    //         departmentId:
    //     }
    // ]
    function createSearchDepartment(departmentList){
        let res = []
        for(let department of departmentList){
            if(res.length==0){
                res.push(
                    {
                        label:department.areaName,
                        value:department.areaName,
                        children:[
                            {
                                label:department.departmentName,
                                value:department.departmentId
                            }
                        ]
                    }
                    )
            }else{
                let isExist = false
                for(let exist of res){
                    console.log(exist.value,department.areaName)
                    if(exist.value==department.areaName){
                        exist.children.push({
                            label:department.departmentName,
                            value:department.departmentId
                        })
                        isExist = true
                        break
                    }
                }
                if(!isExist){
                    res.push(
                        {
                            label:department.areaName,
                            value:department.areaName,
                            children:[
                                {
                                    label:department.departmentName,
                                    value:department.departmentId
                                }
                            ]
                        }
                    )  
                }
            }
        }
        return res
    }
    function getUserInfo(){
        let { name, avatarUrl, sysRoleCode, departmentId ,areaName} = JSON.parse(sessionStorage.getItem("userInfo"));
        let userInfo = {
            userName: name,
            avatarUrl: avatarUrl,
            sysRoleCode: sysRoleCode,
            departmentId: departmentId,
            areaName:areaName
        };

        return userInfo
    }
export default {
    PAGE_SIZE,STATES,CHECK_STATES,USER,COMMENTOR_LEVEL,baseUrl,createSearchDepartment,getUserInfo
}
</script>

<style scoped>

</style>
