import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router/index.js'
const Tip = (message,type)=>{
    Message({
        message,
        type
    })
}

var myAxios = axios.create({
    timeout:10000
})
myAxios.interceptors.response.use(res=>res,err=>{
    console.log(err.response)
    if(err.response.status==401){
        sessionStorage.removeItem('userInfo')
        router.push('/login')
    }
    Tip(err.response.data.message,'error')
    //return Promise.reject(err.response)
})

export default myAxios