<template>
     <div>
        <div class="secondHeader">
            <div class="inputGroup">
                <el-input placeholder="输入用户姓名或联系电话搜索"
                            v-model="searchData.keyWord"
                            prefix-icon="el-icon-search">
                </el-input>
                <el-cascader v-if="myUserLevel==2"
                            v-model="searchData.department"
                            placeholder="请选择队伍"
                            :options="departments"
                            :props="{checkStrictly:true}"
                            clearable>
                </el-cascader>
                <el-button type="text" @click="search">搜索</el-button>
            </div>
            <div class="buttonGroup">
                <el-button type="primary" plain @click="visible=true">新增用户</el-button>
                <el-popover placement="bottom-start" title="导入名单" width="200" trigger="click" v-if="myUserLevel==2">
                    <p>请参照如下模板:</p>
                    <el-link :underline="false" :href="import_url">{{import_url}}</el-link>
                    <el-upload action="" 
                                ref="upload"
                               :before-upload="beforeImport"
                               :http-request="importMenu"
                    >
                    <el-button type="primary" >导入名单</el-button>
                    </el-upload>
                    <el-button type="warning" plain @click="showImportMenu" slot="reference" :loading="import_loading">导入名单</el-button>  
                </el-popover>
                <el-dropdown @command="handleCommand">
                    <el-button>导出名单<i class="el-icon-arrow-down el-icon--right"></i></el-button> 
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0" v-if="myUserLevel==2">导出全部用户</el-dropdown-item>
                        <el-dropdown-item :command="searchData.department" >导出本队伍用户</el-dropdown-item>
                        <el-dropdown-item command="-1" >导出当前页用户</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
            </div>  
        </div>
        <div>
            <el-table   :data="users"
                        stripe
                        style="width:100%;min-width:1000px;"
                        v-loading="table_loading">
                <el-table-column prop="name" label="用户名称"></el-table-column>
                <el-table-column label="角色">
                    <template slot-scope="scope">
                        <span>{{global.USER[scope.row.roleCode-1].name}}</span>
                    </template>
                </el-table-column>
                
                <el-table-column prop="phone" label="联系电话"></el-table-column>
                <el-table-column label="队伍">
                    <template slot-scope="scope">
                        <span>{{scope.row.areaName}}-{{scope.row.departmentName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="单位职务"></el-table-column>
                <el-table-column label="用户等级">
                    <template slot-scope="scope">
                        <el-tag :type="global.COMMENTOR_LEVEL[scope.row.level].type">{{global.COMMENTOR_LEVEL[scope.row.level].label}}</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="missionNum" label="总任务完成数" sortable></el-table-column> -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div v-if="myUserLevel>=scope.row.sysRoleCode">
                            <el-link type="primary" :underline="false" @click="edit(scope.row)">编辑信息</el-link>
                            <el-divider direction="vertical"></el-divider>
                            <el-link type="danger" :underline="false" @click="deleteUser(scope.row.userId,scope.$index)">删除用户</el-link>
                            <!-- <el-link v-else type="danger" :underline="false" disabled>删除用户</el-link> -->
                        </div>
                        <el-link v-else type="info" :underline="false" disabled>不可编辑</el-link>
                    </template>
                </el-table-column>
             </el-table>
             <el-pagination  layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            :total="total"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage">
            </el-pagination>
        </div>
        <el-dialog :visible.sync="visible" v-loading="dialog_loading" title="新增用户" class="container">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px">
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model="form.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="所属队伍：" prop="department">
                    <el-cascader v-model="form.department"
                                 :options="departments">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="单位职务：">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="用户权限：" prop="roleCode">
                    <el-select v-model="form.roleCode" placeholder="用户权限">
                        <el-option v-for="level in validUserLevel" 
                                   :key="level.code" 
                                   :label="level.name"
                                   :value="level.type"> 
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户角色：" prop="levelCode">
                    <el-select v-model="form.levelCode" placeholder="用户角色">
                        <el-option v-for="level in global.COMMENTOR_LEVEL" 
                                   :key="level.value" 
                                   :label="level.label"
                                   :value="level.info"> 
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传头像：">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :http-request="coverUpload"
                        :before-upload="beforeCoverUpload"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"    
                        :limit="1"
                        list-type="picture-card"
                    >
                        <i v-if="form.avatarUrl!=null" class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <el-dialog :visible.sync="avatar_visible" append-to-body>
                        <img width="100%" :src="form.avatarUrl">
                    </el-dialog>
                 </el-form-item>
                <el-form-item class="button-group">
                    <el-button @click="submitForm('form')" type="primary">立即创建</el-button>
                    <el-button @click="visible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="edit_visible" v-loading="edit_loading" title="编辑用户信息" class="container">
            <el-form :model="editForm" ref="editForm" :rules="rules" label-width="120px">
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phone">
                    <el-input v-model="editForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="重置密码：" prop="changePassword">
                    <el-input v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="所属队伍：" prop="department">
                    <el-cascader v-model="editForm.department"
                                 :options="departments">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="单位职务：">
                    <el-input v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="用户权限：" prop="roleCode">
                    <el-select v-model="editForm.roleCode" placeholder="用户权限">
                        <el-option v-for="level in validUserLevel" 
                                   :key="level.code" 
                                   :label="level.name"
                                   :value="level.type"> 
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户角色：" prop="levelCode">
                    <el-select v-model="editForm.levelCode" placeholder="用户角色">
                        <el-option v-for="level in global.COMMENTOR_LEVEL" 
                                   :key="level.value" 
                                   :label="level.label"
                                   :value="level.info"> 
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传头像：">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :http-request="coverUpdate"
                        :before-upload="beforeCoverUpload"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"    
                        :limit="1"
                        list-type="picture-card"           
                    >
                        <img :src="editForm.avatarUrl" class="avatar">
                    </el-upload>
                    <el-dialog :visible.sync="avatar_visible" append-to-body>
                        <img width="100%" :src="editForm.avatarUrl">
                    </el-dialog>
                 </el-form-item>
                <el-form-item class="button-group">
                    <el-button @click="submitEditForm('editForm')" type="primary">更新信息</el-button>
                    <el-button @click="edit_visible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name:'User',
  data(){
   return {
       userInfo:{},
       departments:[],
       searchData:{
           keyWord:"",
           department:[],
       },
       table_loading:false,
       users:[],
       currentPage:1,
       total:undefined,
       visible:false,
       validUserLevel:[],
       myUserLevel:null,
       //todo
       form:{
           name:"",
           phone:"",
           password:"",
           roleCode:"",
           levelCode:"",
           department:[],
           avatarUrl:"",
           title:""
       },
       avatar_visible:false,
       rules:{
           name:[
               {required:true,message:"请输入姓名",trigger:'blur'}
           ],
           phone:[
               {required:true,message:"请输入手机号",trigger:'blur'},
               {pattern:/^1[0-9]{10}$/,required:true,message:'请输入正确的手机号',trigger:'blur'}
           ],
           department:[
               {required:true,message:'请选择队伍',trigger:'change'}
           ],
           password:[
               {required:true,message:'请设置密码',trigger:'blur'},
                {min:6,message:'密码太短',trigger:'blur'}
           ],
           changePassword:[
                {min:6,message:'密码太短',trigger:'blur'}
           ],
           roleCode:[
               {required:true,message:'请选择用户权限',trigger:'blur'}
           ],
           levelCode:[
               {required:true,message:'请选择用户角色',trigger:'blur'}
           ],
       },
       dialog_loading:false,
       import_url:"",
       import_loading:false,
       edit_visible:false,
       edit_loading:false,
       editForm:{
           userId:"",
           name:"",
           phone:"",
           password:"",
           roleCode:"",
           levelCode:"",
           department:[],
           avatarUrl:"",
           title:""
       },
   }
  },
  mounted(){
      this.init()
  },
  methods:{
      init(){
          //this.table_loading = true
          this.userInfo = this.global.getUserInfo()
          this.departments = JSON.parse(sessionStorage.getItem('departments'))
          if(this.userInfo.sysRoleCode==2){
              this.validUserLevel = this.global.USER
              this.myUserLevel = 2
          }
          else{
              this.validUserLevel = this.global.USER.slice(0,3)
              this.myUserLevel = 1
              console.log('departments',this.departments)
              this.searchData.department = this.departments.children[0].value
          }   
          this.search(false)
      },
      handleCurrentChange(){
          this.search(true)
      },
      //搜索
      search(isCurrentChange){
        this.table_loading = true
        let form  = {
            keyWord:this.searchData.keyWord,
            pageNo:this.currentPage-1,
            pageSize:10,
            departmentId:null,
            areaName:null
        }
        if(typeof(this.searchData.department)==='object'){
            if(this.searchData.department.length==0)
                form.departmentId = 0
            else if(this.searchData.department.length==1)
                form.areaName = this.searchData.department[0]
            else
                form.departmentId = this.searchData.department[1]
        }else{
            form.departmentId = this.searchData.department
        }
        
        console.log(form)
        this.$axios.post('/api/user/list',form).then(res=>{
            if(!isCurrentChange)
                this.currentPage = 1
            this.users = res.data.data.content
            console.log(this.users)
            this.total = res.data.data.totalElements
        })
        // .catch(err=>{
        //     this.$message({
        //         message:err,
        //         type:"error"
        //     })
        //     this.table_loading = false
        // })
        .finally(()=>{
            this.table_loading = false;
        })
      },
      fliterState(value,row){
          return row.state === value
      },
       beforeCoverUpload(file){
            const IMG_TYPE = ['image/jpg', 'image/jpeg', 'image/png']
            const isImg = IMG_TYPE.includes(file.type)
            const size = file.size / 1024 / 1024 < 10

            if (!isImg) {
                this.$message.error('请选择图片作为头像!');
            }
            if (!size) {
                this.$message.error('上传头像图片大小不能超过10MB!');
            }
            console.log(isImg&& size)
            return isImg && size;
        },
        coverUpload(file){
            this.edit_loading = true
            console.log('upload',file)
            let formData = new FormData()
            formData.append('file',file.file)
            this.$axios.post('/api/file/upload',formData).then(res=>{
                console.log('res',res)
                this.form.avatarUrl = res.data.data
                this.$message.success('图片上传成功')
                this.edit_loading = false
            })
            // .catch(err=>{
            //     console.log('err',err.response)
            //     this.$message.error('上传失败')
            //     this.edit_loading = false
            // })
        },
      handlePreview(){
            this.avatar_visible = true
        },
        handleRemove(file){
            console.log("here",file)
        },
        //创建用户的表单提交
      submitForm(formName){
          console.log(this.form)  
          this.$refs[formName].validate((valid)=>{
              if(valid){    
                  this.dialog_loading = true
                  let form = {
                      name:this.form.name,
                      phone:this.form.phone,
                      password:this.form.password,
                      role:this.form.roleCode,
                      level:this.form.levelCode,
                      departmentId:this.form.department[1],
                      title:this.form.title,
                      avatarUrl:this.form.avatarUrl
                  }
                  this.$axios.post('/api/user/newFromAdmin',form)
                    .then(res=>{
                        console.log('here',res.response)
                        console.log(res.data.data)
                        this.$message({
                            message:"创建成功",
                            type:"success"
                        })
                        if(this.currentPage!=1||this.searchData.keyWord!=""||(this.myUserLevel==2&&this.searchData.department.length!=0)){
                            console.log(this.searchData.department)
                            this.searchData.keyWord = ""
                            this.currentPage = 1
                            if(this.myUserLevel==2)
                                this.searchData.department = []
                            this.search()
                        }
                        else{
                            this.users.unshift(res.data.data)
                            this.total++
                            if(this.total>10)
                                this.users.pop()
                        }
                        this.visible = false
                    })
                    // .catch(err=>{
                    //     this.$message({
                    //         message:err,
                    //         type:"error"
                    //     })
                    //     this.dialog_loading = false
                    // })
                    .finally(()=>{
                        this.dialog_loading = false;
                    })
                }else{
                    this.$message({
                        message:"表单填写不规范",
                        type:'error'
                    })
                    
                }
          })
      },
      cancelForm(formName){
        this.$refs[formName].resetFields()
        this.form.avatarUrl = ""
        this.editForm.avatarUrl = ""
      },
      showImportMenu(){
          this.import_loading = true
          this.$axios.get(`/api/config/IMPORT_USER_EXCEL_TEMPLATE`).then(res=>{
              console.log(res)
              this.import_url = res.data.data.configValue
              this.import_loading = false
          })
      },
      beforeImport(file){
            const isExcel = file.type ==='application/vnd.ms-excel'
            const isExcelComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (!(isExcel||isExcelComputer)) {
                this.$message.error('上传文件只能是xls/xlsx格式!');
            }
            return isExcel||isExcelComputer
      },
      importMenu(file){
          let formData = new FormData()
          formData.append('file',file.file)
          this.$axios.post('/api/user/import/excel',formData).then(res=>{
            console.log('res',res)
            let msg = new Promise(resolve=>{
                if(res.data.data.successCount>0){
                    console.log('000')
                    this.$message({
                        type:'success',
                        message:`成功${res.data.data.successCount}条`
                    })
                }
                if(res.data.data.errorCount>0){
                    let msg = ''
                    for(let i in res.data.data.rowErrorMsg){
                        msg += `第${i}行:${res.data.data.rowErrorMsg[i]}`
                    }
                    this.$message({
                        type:'error',
                        message:`失败${res.data.data.errorCount}条`+`${msg}`
                    })
                }
                resolve()
            })
            msg.then(()=>{
                console.log(res)
                setTimeout(()=>{
                    console.log(this.searchData)
                    this.currentPage = 1
                    this.searchData.keyWord = ""
                    this.searchData.departmentId = this.userInfo.sysRoleCode==2?0:this.userInfo.departmentId
                    this.search(false)
                },1000)
            }) 
              //location.reload()
          })
        //   .catch(err=>{
        //       this.$message.error(err)
        //   })
      },
    
      handleCommand(command){
          if(typeof(command)=="object")
            if(command.length<2){
                this.$message({
                        message:"请选择队伍",
                        type:'error'
                })
                return
            }
            else
                command = command[1]
        this.output(command)
      },
      output(command){
        if(command>=0){
            this.$axios.get(`/api/user/department/${command}/export`,{responseType: 'blob'}).then(res=>{
                  console.log(res)
                  let blob = new Blob([res.data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                  const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = e => {
                    const a = document.createElement('a');
                    a.href = e.target.result;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
              })
        }else{
            this.$axios.post('/api/user/export/current_page',this.users,{responseType: 'blob'}).then(res=>{
                console.log(res)
                let blob = new Blob([res.data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = e => {
                    const a = document.createElement('a');
                    a.href = e.target.result;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
              })
        }
      },
      handleEditPreview(){
            this.edit_visible = true
        },
        handleEditRemove(file){
            console.log("here",file)
        },
        edit(userInfo){
            console.log(userInfo)
            this.editForm.userId = userInfo.userId
            this.editForm.name = userInfo.name
            this.editForm.phone = userInfo.phone
            this.editForm.department = [userInfo.areaName,userInfo.departmentId]
            this.editForm.title = userInfo.title
            this.editForm.roleCode = this.global.USER[userInfo.roleCode-1].type
            this.editForm.levelCode = this.global.COMMENTOR_LEVEL[userInfo.level].info
            this.editForm.avatarUrl = userInfo.avatarUrl
            this.edit_visible = true
        },
        coverUpdate(file){
            this.edit_loading = true
            console.log('upload',file)
            let formData = new FormData()
            formData.append('file',file.file)
            this.$axios.post('/api/file/upload',formData).then(res=>{
                console.log('res',res)
                this.editForm.avatarUrl = res.data.data
                this.$message.success('图片上传成功')
            })
            // .catch(()=>{
            //     this.$message.error('上传失败')
            //     this.edit_loading = false
            // })
            .finally(()=>{
                this.edit_loading = false;
            })
        },
        submitEditForm(formName){
             this.$refs[formName].validate((valid)=>{
              if(valid){    
                  this.edit_loading = true
                  let form = {
                      userId:this.editForm.userId,
                      name:this.editForm.name,
                      phone:this.editForm.phone,
                      password:this.editForm.password,
                      role:this.editForm.roleCode,
                      level:this.editForm.levelCode,
                      departmentId:this.editForm.department[1],
                      title:this.editForm.title,
                      avatarUrl:this.editForm.avatarUrl
                  }
                  this.$axios.put('/api/user/update',form)
                            .then(res=>{
                                // console.log(res.data.data)
                                this.$message({
                                    message:"更新成功",
                                    type:"success"
                                })
                                if(res.data.data.userId==JSON.parse(sessionStorage.getItem("userInfo")).userId){
                                    this.$emit('updateUserInfo',res.data.data)
                                }
                               for(let index=0;index< this.users.length;index++){
                                   if(this.users[index].userId==res.data.data.userId){
                                      this.users = [...this.users.slice(0,index),res.data.data,...this.users.slice(index+1,this.users.length)]
                                    
                                      break;
                                   }
                               }
                                this.edit_visible = false
                            })
                            // .catch(err=>{
                            //     this.$message({
                            //         message:err,
                            //         type:"error"
                            //     })
                            //     this.edit_loading = false
                            // })
                            .finally(()=>{
                                this.edit_loading = false
                            })
              }
              else{
                    this.$message({
                        message:"表单填写不规范",
                        type:'error'
                    })
                    
              }
          })
        },
      deleteUser(userId,index){
          console.log(userId,index)
          this.$confirm('确定要删除吗','提示',{
              confirmButtonText:'确定',
              cancelButtonText:'取消',
              type:'warning'
          }).then(()=>{
              this.$axios.delete(`/api/user/${userId}`)
                .then(()=>{
                    this.users.splice(index,1)
                    this.$message({
                        type:'success',
                        message:'删除成功！'
                    })
                })
          })
          
      }
  },
  watch:{
      visible(){
          if(this.visible==false){
               this.cancelForm('form')
          }
      },
       edit_visible(){
          if(this.edit_visible==false){
               this.cancelForm('editForm')
          }
      },
  }
}
</script>

<style src='../../../assets/css/secondHeader.css' scoped>
</style>
<style src='../../../assets/css/table.css' scoped>
</style>
<style src='../../../assets/css/dialog.css' scoped>
</style>
