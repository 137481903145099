import Vue from 'vue'
import Router from 'vue-router'

import Mission from '../components/menu/mission/Mission.vue'
import Script from '../components/menu/script/Script.vue'
import User from '../components/menu/user/User.vue'
import KPI from '../components/menu/kpi/KPI.vue'
    
import Statistics from '../components/menu/Statistics.vue'

import Login from '../components/log/Login.vue'
//import Home from '../components/home/Home.vue'
Vue.use(Router)

export default new Router({
    routes:[
        {
            path:'/login',
            component:Login,
            meta:{title:'登录'}
        },
        {
            path: '/',
            // alias:'/home',
            meta:{
                requireAuth:true,
            },
            redirect:'/mission',
            component: ()=>import('../components/home/Home.vue'),
            children:[
                {
                    path:'/mission',
                    component:Mission,
                    children:[
                        {
                            path:'',
                            component:()=>import('../components/menu/mission/MissionTable.vue'),
                            meta:{title:'网评管理'},
                        },
                        {
                            path:':taskId',
                            name:'MissionDetail',
                            meta:{title:'网评详情'},
                            component:()=>import('../components/menu/mission/MissionDetail.vue'),
                        },
                        
                    ]
                },
                {
                    path: '/script',
                    component: Script,
                    children:[
                        {
                            path:'',
                            name:'ScriptTable',
                            meta:{title:'投稿管理'},
                            component:()=>import('../components/menu/script/ScriptTable.vue')
                        },
                        {
                            path:':taskId',
                            name:'ScriptDetail',
                            meta:{title:'投稿详情'},
                            component:()=>import('../components/menu/script/ScriptDetail.vue')
                        },
                    ]
                },
                {
                    path: '/user',
                    meta:{title:'队伍管理'},
                    component: User
                },
                {
                    path: '/kpi',
                    component: KPI,
                    meta:{title:'绩效考核'},
                    children:[
                        {
                            path:'',
                            meta:{title:'绩效考核'},
                            component:()=>import('../components/menu/kpi/KPITable')
                        },
                    ]
                },
                {
                    path: '/statistics',
                    meta:{title:'数据分析'},
                    component: Statistics
                },
            ]
        },
    ],
    beforeEach:(to,from,next)=>{
        console.log('here')
        if(to.matched.some(record => record.meta.requireAuth)){
          if(sessionStorage.getItem('userInfo'))
            next()
          else{
            next({
              path:'/login',
              query:{redirect:to.fullPath}
            })
          }
        }else{
          next()
        }
      }
})