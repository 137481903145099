<template>
    <router-view/>
</template>

<script>
export default {
  name:'',
  data(){
   return {
       
   }
  }
}
</script>

<style scoped>

</style>
