<template>
    <div>
        <div id="statistic-header">
            <div class="statistic-header-title">
                <span style="float:left">系统统计</span>
            </div>
            <div id="statistic-cardlist">
                <el-row :gutter="20" >
                    <el-col :span="4">
                        <div class="statistic-card">
                            <p>总人数</p>
                            <p>{{statistics.totalUserCount}}</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="statistic-card">
                            <p>累计投稿数</p>
                            <p>{{statistics.totalArticleCount}}</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="statistic-card"> 
                            <p>累计任务数</p>
                            <p>{{statistics.totalTaskCount}}</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="statistic-card"> 
                            <p>累计任务完成数</p>
                            <p>{{statistics.totalTaskResultCount}}</p>
                        </div>
                    </el-col>
                    <!-- <el-col :span="4">
                        <div class="statistic-card">
                            <p>平均任务完成率</p>
                            <p>1%</p>
                        </div>
                    </el-col> -->
                </el-row>
            </div>  
        </div>
        <div id="statistic-chartlist">
            <el-row :gutter="20">
                <el-col :span="9">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">用户数量</span>
                        </div>
                        <!-- <div id="userCount" class="trueChart"></div> -->
                        <el-table :data="table_data" max-height="310" stripe="true">
                            <el-table-column v-for="head in mock_header" :key="head.prop" :label="head.label" :prop="head.prop"></el-table-column>
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="15">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">支队任务完成数</span>
                        </div>
                        <div id="departmentCount" class="trueChart"></div>
                    </div>   
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <!-- <el-col :span="12">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">近期投稿完成情况</span>
                        </div>
                        <div id="commitCount" class="trueChart"></div>
                    </div>
                </el-col> -->
                <el-col :span="24">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">支队稿件推送数据</span>
                        </div>
                        <div id="dailyCommitCount" class="trueChart"></div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">队伍指令完成率TOP10</span>
                        </div>
                        <el-table :data="departmentTop">
                            <el-table-column prop="areaName" label="支队"></el-table-column>
                            <el-table-column prop="departmentName" label="队伍"></el-table-column>
                            <el-table-column prop="resultCount" label="投稿总数"></el-table-column>
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="chart">
                        <div class="chart-title">
                            <div class="divider"></div>
                            <span style="float:left">用户指令完成TOP10</span>
                        </div>
                        <div>
                            <el-table :data="userTop">
                                <el-table-column prop="name" label="姓名"></el-table-column>
                                <el-table-column label="所属队伍">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.areaName}}-{{scope.row.departmentName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="taskCount" label="任务总数"></el-table-column>
                                <el-table-column prop="completeCount" label="实际投稿数"></el-table-column>
                                <el-table-column label="投稿完成率">
                                    <template slot-scope="scope">
                                        <span>{{(scope.row.completeRate*100).toFixed(2)}}%</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

 <script>
 //import taizhou_geoJSON from '../../assets/泰州市.json'
import {Chart} from '@antv/g2';
import { DataView } from '@antv/data-set';
export default{
    name:'Statistics',
    data(){
        return{
            statistics:{},
            userCount:[],
            departmentTop:[],
            userTop:[],
            mock_header:[],
            table_data:[],
            areaTaskResultCount:[],
        }
    },
    created(){
        this.initMockHeader();
        this.initTableData();
        this.$axios.get('/api/index/statistics').then(res=>{
           console.log('全部数据',res.data)
           this.initAreaTaskResultCount(res.data.data.areaTaskResultCount)
           this.statistics = res.data.data  
           this.departmentTop = this.statistics.departmentResultStatistics.slice(0,10)
           this.userTop = this.statistics.userTaskStatistics.slice(0,10)
           let {volunteerCount,baseMemCount,mainstayMemCount,coreMemCount,expertMemCount} = this.statistics
           this.userCount = [
               {type:"专家成员",value:expertMemCount},
               {type:"核心成员",value:coreMemCount},
               {type:"骨干成员",value:mainstayMemCount},
               {type:"基础成员",value:baseMemCount},
               {type:"志愿者",value:volunteerCount},
           ]
           
            //this.initUserCount()
            this.initDepartCount()
            //this.initCommitCount()
            
       })
       this.$axios.get('/api/mock_data/article_push_info').then(res=>{
        console.log(res.data);
        // const mockData = [
        //     {Team:'我的队伍们11', city: 0, province: 30, net: 30},
        //     {Team:'我的队伍们12', city: 3000, province: 3000, net: 3000},
        //     {Team:'我的队伍们13', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们14', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们15', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们16', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们17', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们18', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们119', city: 30, province: 50, net: 80},
        //     {Team:'我的队伍们110', city: 30, province: 50, net: 80},
        // ]
        this.initDailyCommitCount(res.data.data);
        // this.initDailyCommitCount(mockData);
       })
       
       //this.initMap()
    },

    methods:{
        // initMap(){
        //     const highCharts = require('highcharts/highmaps')
        //     highCharts.mapChart('chart2', {
        //         title: {
        //             text: '任务完成情况'
        //         },
        //         mapNavigation: {
        //             enabled: true,
        //             buttonOptions: {
        //                 verticalAlign: 'bottom'
        //             }
        //         },
        //         colorAxis: {
        //             tickPixelInterval: 100
        //         },
        //         series: [{
        //             data: this.chart2_data,
        //             mapData: taizhou_geoJSON,
        //             joinBy: 'name',
        //             keys: ['name', 'value'],
        //             name: '区域投稿数目',
        //             states: {
        //                 hover: {
        //                     color: '#a4edba'
        //                 }
        //             },
        //             dataLabels: {
        //                 enabled: true,
        //                 format: '{point.properties.postal}'
        //             }
        //         }]
        //     });
        // },
        // initUserCount(){
        //     const chart = new Chart({
        //         container: 'userCount',
        //         autoFit: true,
        //         syncViewPadding:true
        //     });
        //     chart.data(this.userCount)
        //     chart.scale('value',{
        //         alias:'人数',
        //         range:[0,0.9]
        //     });

        //     chart.axis('type',{
        //         tickLine:{
        //             alignTick:false
        //         }
        //     })
        //     chart.axis('value')

        //    chart.tooltip({
        //        showMarkers:false,
        //    }).legend(false)
        //     chart.interval().position('type*value')
        //           .color('type',['#7FFFAA','#87CEFA','#87CEFA','#FF8C00','#FF8C00'])
        //     chart.interaction('element-active')
            
        //     this.userCount.forEach(item => {
        //         chart.annotation().text({
        //             position:[item.type,item.value],
        //             content:item.value,
        //             style:{
        //                 textAlign:'center'
        //             },
        //             offsetY:-10
        //         })
        //     });
        //     chart.render();
        // },
        initDepartCount(){
            const chart = new Chart({
                container: 'departmentCount',
                autoFit: true,
                syncViewPadding:true,
            });
            
            chart.data(this.areaTaskResultCount)
            chart.scale('value',{
                alias:'投稿数',
                range:[0,0.9]
            });

            chart.axis('area',{
                tickLine:{
                    alignTick:false
                },
                label:{
                    autoRotate:false,
                    autoHide: false,
                    autoEllipsis: false,
                    formatter: value => value.length < 5 ? value : value.length < 10 ? value.substring(0, Math.ceil(value.length / 3)) + '\n' + value.substring(Math.ceil(value.length / 3))
                    : value.substring(0, 5) + '\n' + value.substring(5, 10) + '...'
                }
            })
            chart.axis('value')

            chart.tooltip({
                showMarkers:false
            }).legend(false)
            chart.interval().position('area*value')
            chart.interaction('element-active')

            this.areaTaskResultCount.forEach(item => {
                chart.annotation().text({
                    position:[item.area,item.value],
                    content:item.value,
                    style:{
                        textAlign:'center'
                    },
                    offsetY:-10
                })
            });
            chart.render();
        },
        initCommitCount(){
            const chart = new Chart({
                container: 'commitCount',
                autoFit: true,
                syncViewPadding:true
            });
            let data = []
            for(let item in this.statistics.recentArticleCommitCount){
                data.push({title:item,value:this.statistics.recentArticleCommitCount[item]})
            }
            console.log(data)
            chart.data(data)
            chart.scale({
                value:{
                    alias:'投稿数',
                    min:Math.min(data[0].value,data[1].value,data[2].value)-2>0?Math.min(data[0].value,data[1].value,data[2].value)-2:0,
                    max:Math.max(data[0].value,data[1].value,data[2].value)+2,
                },
                title:{
                    range:[0.1,0.9]
                }
            }
            )
            chart.tooltip({
                showCrosshairs:true,
                shared:true
            }).legend(false)
            chart.line().position('title*value').color('#1890ff').label('value')
            chart.point().position('title*value').tooltip(false)
            chart.render()
        },
        initDailyCommitCount(data){
            const transform = (arr) => {
                arr.forEach(item => {
                    item['市推'] = item.city;
                    item['省推'] = item.province;
                    item['全网推'] = item.net
                    delete item.city
                    delete item.province
                    delete item.net
                })
            }
            transform(data);

            const groups = ['市推', '省推', '全网推'];
            const colorMap = {
                '市推':'#E3F4BF',
                '省推':'#36CFC9',
                '全网推': '#0860BF',
            }
            const dv = new DataView();
            dv.source(data)
            .transform({
                type:'fold',
                fields: groups,
                key:'name',
                value:'value',
                retains: ['Team'],
            })
            console.log(dv.rows);
            const chart = new Chart({
                container: 'dailyCommitCount',
                autoFit: true,
            });

            chart.data(dv.rows);
            chart.scale({
                value:{
                    alias:'数目',
                    range:[0, 0.9],
                },
            });
            chart.tooltip({
                showMarkers: false,
                shared: true,
                marker:{
                    textAlign:'start',
                }
            });

            chart.axis('Team',{
                label:{
                    autoRotate:false,
                    autoHide: false,
                    autoEllipsis: false,
                    formatter: value => value.length < 5 ? value : value.length < 10 ? value.substring(0, Math.ceil(value.length / 3)) + '\n' + value.substring(Math.ceil(value.length / 3))
                    : value.substring(0, 5) + '\n' + value.substring(5, 10) + '...'
                }
            })
            chart
                .interval()
                .position('Team*value')
                .label('value',{
                    formatter: value => value,
                    layout:{
                        type: 'fixed-overlap'
                    }
                })
                .color('name', name => colorMap[name])
                .tooltip('name*value', (name, value) => {
                    return {
                        name,value
                    }
                })
                .adjust([
                    {
                        type: 'dodge',
                        dodgeBy: 'name',
                        marginRatio: 0,
                    },
                ])

            chart.interaction('active-region')

            dv.rows.forEach(item => {
                console.log(item)
            });
            chart.render()
        },
        // sortAreaTaskResultCount(){
        //      let areaTaskResultCount = {}
        //     for(let departName of areaSort){
        //         areaTaskResultCount[departName] = this.statistics.areaTaskResultCount[departName]
        //     }
        //     this.statistics.areaTaskResultCount = areaTaskResultCount
        // }
        initMockHeader(){
            return this.$axios.get('/api/mock_data/table_header').then(res=>{
                this.mock_header = res.data.data
                console.log(res.data.data)
            })
            // this.mock_header = [
            //     {label:'队伍名称',prop:'name'},
            //     {label:'队伍人数',prop:'subTeamCount'},
            //     {label:'中队名称',prop:'subTeamUserCount'},
            //     {label:'中队人数',prop:'userCount'},
            //     ]
        },
        initTableData() {
            return this.$axios.get('/api/mock_data/user').then(res=>{
               this.table_data = res.data.data
               
            })
        },
        initAreaTaskResultCount(areaTaskResultCount){
            // let obj = []
            // for(let key of Object.getOwnPropertyNames(areaTaskResultCount)){
            //     obj.push({
            //         area:key,
            //         value:areaTaskResultCount[key]
            //     })
            // }
            // obj = obj.filter(x=>x.area!=='全部地区')
            // this.areaTaskResultCount = obj
            this.areaTaskResultCount = areaTaskResultCount;
            console.log(this.areaTaskResultCount);
        }
    },
}

</script>

<style scoped>
#statistic-header{
    width: 100%;
    min-width:1000px;
    height: 120px;
   
    padding:5px;
    margin-bottom: 10px;
}
.statistic-header-title{
    height: 20px;
    font-size: 14px;
    /* border-bottom: 1px solid ; */
    margin-bottom: 10px;
}

.statistic-card{
    line-height:1.2;
    height: 88px;
    border-radius: 4px;
    background-color: rgba(56, 121, 238, 0.13);
    text-align: center;
    border: 1px solid rgba(56, 121, 238, 1);
    color: rgba(56, 121, 238, 1);
}
.statistic-card p:first-child{
    font-size: 14px;
}
.statistic-card p:nth-child(2){
    font-size: 30px;
    line-height: 0;
}
#statistic-chartlist{
    /* border:1px solid red; */
    min-width:1000px;
}
.statistic-chart-buttonGroup{
    float: right;
}
.chart{
    margin:10px;
    height: 350px;
    /* width: 400px; */
}
.trueChart{
    /* height: 310px; */
    height: 320px;
}
.chart-title{
    height: 30px;
}
</style>
