<template>
    <div class="background" >
    <div id="panel">
        <div id="panel_leftchild" >
            
            <!-- <el-image :src="illustrationUrl" :fit="fit" 
                    style="width:100px;height:100px;bottom:0">
            </el-image> -->
        </div>
        <div id="panel_rightchild">
            <div id="login_firstline">
                <el-avatar :src="icon" :size="100" fit="cover"></el-avatar>
            </div>
            
            <el-form ref="type_password" :model="type_password" :rules="rules_password">
                <el-form-item prop="phone">
                    <el-input placeholder="手机号/账号" v-model="type_password.phone" prefix-icon="el-icon-s-custom"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input   placeholder="密码" 
                                v-model="type_password.password" 
                                show-password
                                @keyup.enter.native="submitByPassword('type_password')"
                                prefix-icon="el-icon-lock"
                            >
                    </el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-link style="float:right;height:18px;" @click="findPassword">忘记密码？</el-link>
                </el-form-item> -->
                <el-form-item>
                    <el-button @click="submitByPassword('type_password')"  class="log">登录</el-button>
                </el-form-item>
            </el-form>
                
    
            
        </div>
    </div>
        <div class="footer">
            <span>主办单位:泰州网络宣传中心（泰州市网络安全应急指挥中心、泰州市互联网违法和有害不良信息举报中心）</span>
            <el-link href="https://beian.miit.gov.cn/" style="color:#9499A0;font-size:10px" :underline="false" target="_blank">互联网ICP备案:苏ICP备08120664号</el-link><br>
            <span>Copyright 2021-2022 泰州网络宣传中心（泰州市网络安全应急指挥中心、泰州市互联网违法和有害不良信息举报中心）版权所有</span>
        </div>
    </div>
</template>

<script>
import icon from '../../../public/icon.png'

export default {
  name:'Login',
  data(){
   return {
       icon:icon,
       type_password:{
           password:'',
           phone:'',    
       },
       rules_password:{
           password:[
               {required:true,message:'请输入密码',trigger:'blur'},
           ],
           phone:[
               {pattern:/^1[0-9]{10}$/,required:true,message:'请输入正确的手机号',trigger:'blur'},
           ]
       },
       illustrationUrl:'',
       backImgUrl:'',
   }
  },

  methods:{
      submitByPassword(formName){
          this.$refs[formName].validate(valid=>{
              if(valid){
                this.$axios.post('/api/user/web/login',
                                    this.type_password)
                     .then((res=>{
                            console.log(res)
                         if(res.data.data.sysRoleCode==0){
                             this.$message.error('您的账号不具有管理员权限')
                         }else{
                            let userInfo = res.data.data
                            sessionStorage.setItem("userInfo",JSON.stringify(userInfo))

                            if(userInfo.sysRoleCode==2){
                                this.$axios.get('/api/department/groups').then(res=>{
                                    let departments = this.initDepartmentData(res.data.data)
                                    sessionStorage.setItem("departments",JSON.stringify(departments))
                                    
                                })
                            }else if(userInfo.sysRoleCode==1){
                                let departments = [{
                                    value:userInfo.areaName,
                                    label:userInfo.areaName,
                                    children:[
                                        {
                                            value:userInfo.departmentId,
                                            label:userInfo.departmentName
                                        }
                                    ]
                                }]
                                sessionStorage.setItem("departments",JSON.stringify(departments))
                            }
                            this.$router.push("/")
                         }
                     }))
                    //  .catch(err=>{
                    //      this.$message({
                    //          message:err,
                    //          type:"error"
                    //      })
                    //  })
                }
          })
          
      },
      initDepartmentData(data){
            let department = []
            for(let key in data){
                let item = {
                    label:key,
                    value:key,
                    children:[]
                }
                for(let child of data[key]){
                    item.children.push({
                        value:child.departmentId,
                        label:child.departmentName
                    })
                }
                department.push(item)
            }
            console.log('initDepartment',department)
            return department
        },
  },
  
  mounted(){
    document.querySelector('body').setAttribute('style','margin:0')
    // this.illustrationUrl = illustration
    // this.backImgUrl = backImg
  }
}
</script>

<style src='../../assets/css/login.css' scoped>
    
</style>
<style scoped>
    .footer {
        position: fixed;
        left:50%;
        bottom: 30px;
        height: 50px;
        transform: translate(-50%,0);

    }
    .footer span{
        color:#9499A0;
        font-size: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
</style>
